.background {
  background-color: red;
}
.header-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 16px;
  width: 95%;
  margin: 20px auto;
  padding: 20px 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-shadow: 0px 2px 10px #c4c4c47a;

  .total-tokens {
    .out-status,
    .in-status {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .ant-progress.ant-progress-circle {
        margin-right: 30px;

        .ant-progress-text {
          font: normal normal normal 30px/51px Gilroy-SemiBold;
          color: #11141a;
        }
      }

      .total-status {
        p {
          text-align: left;
          font: normal normal normal 15px/21px Gilroy-Medium;
        }

        span {
          text-align: left;
          font: normal normal normal 16px/21px Gilroy-Bold;
          color: #1b1b1e;
        }
      }

      .total-time {
        background: #f2f9ff 0% 0% no-repeat padding-box;
        border-radius: 10px;
        text-align: left;
        font: normal normal normal 16px/22px Gilroy-Medium;
        color: #222222;
        padding: 5px 10px;
      }
    }

    .out-status {
      .total-status {
        span {
          color: #fe9259;
        }
      }
    }

    .in-status {
      .total-status {
        span {
          color: #5985fe;
        }
      }
    }

    h2 {
      text-align: center;
      font: normal normal normal 22px/31px Gilroy-SemiBold;
      color: #11141a;
    }
  }
}

.info-card {
  padding: 0 5px;
}

.info-container {
  height: 120px;
  padding: 30px;
  border: 0px solid;
  border-radius: 10px;
  box-shadow: 0px 3px 12px #0000000f;
}
.bg-image {
  background-image: url('../../images/Group5547.svg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.dashboard-header {
  width: 95%;
  margin: 20px auto;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -10px;

    h6 {
      margin: 0;
      padding: 0;
      font: normal normal normal 22px/31px Gilroy-SemiBold;
      letter-spacing: 0px;
      color: #11141a;
    }
  }
}

.loader {
  width: 95%;
  margin: auto;
  text-align: center;
}

.stream-list {
  width: 95%;
  margin: auto;
  position: relative;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: separate;
  -webkit-border-horizontal-spacing: 0px;
  -webkit-border-vertical-spacing: 5px;
  position: relative;
  padding-bottom: 20px;

  .header-row {
    position: relative;
    top: -6px;

    th {
      text-align: center;
      font: normal normal normal 13px/19px Gilroy-SemiBold;
      color: #888888;
      text-transform: uppercase;
      opacity: 1;
    }
  }

  .data-row {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 10px;
    cursor: pointer;
    position: relative;

    td {
      text-align: center;
      padding: 10px;
    }
  }
}

.no-streams {
  font-family: 'Gilroy-SemiBold';
  margin-top: 20px;
  font-size: 1.2rem;
  text-align: center;
}

.inout {
  font: normal normal normal 16px/22px Gilroy-SemiBold;
  color: #222222;

  .out {
    color: #fe9259;
  }

  .in {
    color: #5985fe;
  }
}

.status span {
  background: #f2f9ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: left;
  font: normal normal normal 16px/22px Gilroy-Medium;
  color: #222222;
  margin: 10px;
}

.from,
.to {
  font: normal normal normal 18px/26px Gilroy-Medium;
  color: #11141a;
  cursor: pointer;

  img {
    padding: 0;
    width: 15px;
  }
}

.value {
  font: normal normal normal 20px/29px Gilroy-SemiBold;
  color: #11141a;
  position: relative;
  left: 0px;
}

.value span {
  font: normal normal normal 14px/20px Gilroy-Medium;
  letter-spacing: 0.58px;
  color: #666666;
  text-transform: capitalize;
}

.progressX {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
    font: normal normal normal 14px / 20px Gilroy-Medium;
    font-size: 10px;
    color: #666666;

    span {
      font-weight: bold;
    }
  }

  .ant-progress.ant-progress-line {
    padding: 0;
    margin: 0;
  }
}

.time {
  font: normal normal normal 16px/22px Gilroy-Medium;
  letter-spacing: 0.32px;
  color: #666666;
  text-transform: capitalize;
}

.link a {
  border: 1px solid #2b8dff;
  border-radius: 6px;
  font: normal normal normal 16px/13px Gilroy-SemiBold;
  color: #2b8dff;
  text-decoration: none;
}

.action {
  font-family: 'Gilroy-SemiBold';

  .cancel-btn {
    background: #ffdb8b;
    border: none;
    outline: none;
    border-radius: 5px;

    &:hover,
    &:focus {
      background: linear-gradient(45deg, #fe9259, #ffdb8b);
      transition: all 0.5s ease-in;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .withdraw-btn {
    background: #afc4ff;
    border: none;
    outline: none;
    border-radius: 5px;

    &:hover,
    &:focus {
      background: linear-gradient(45deg, #5985fe, #afc4ff);
      transition: background 0.5s ease-in;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
}

.connect-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    font-family: 'Gilroy-Medium';
  }

  .wallet {
    border-radius: 100rem;
    padding: 1rem;
    font-family: 'Gilroy-SemiBold';
    font-size: 1rem;
    padding: 0rem 1rem;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #78e4ff, #ff48fa);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;

    &:hover {
      box-shadow: none;
      color: white;
    }
  }
}

.modalX {
  .ant-modal {
    width: 80% !important;

    @media screen and (min-width: 500px) {
      width: 50% !important;
    }

    @media screen and (min-width: 768px) {
      width: 40% !important;
    }

    @media screen and (min-width: 1024px) {
      width: 30% !important;
    }

    @media screen and (min-width: 1280px) {
      width: 20% !important;
    }
  }

  .ant-modal-body {
    text-align: center;

    h5 {
      font: normal normal normal 20px/22px Gilroy-SemiBold;
      margin-bottom: 25px;
    }

    p {
      background: #c5c5c5 0% 0% no-repeat padding-box;
      border-radius: 10px;
      font: normal normal normal 14px/22px Gilroy-Medium;
      color: #222222;
      padding: 5px 10px;
    }

    .submit-btn {
      border: none;
      border-radius: 3px;
      padding: 5px 10px;
      color: #ffffff;
      background-color: #5985fe;
    }
  }
}

.wrong-network-modal {
  .ant-modal {
    width: 90% !important;

    @media screen and (min-width: 500px) {
      width: 70% !important;
    }

    @media screen and (min-width: 768px) {
      width: 60% !important;
    }

    @media screen and (min-width: 1024px) {
      width: 50% !important;
    }
  }
}
