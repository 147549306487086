button.button {
  border-radius: 100rem;
  padding: 1rem;
  font-family: 'Gilroy-SemiBold';
  font-size: 1rem;
  padding: 0rem 1rem;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #78e4ff, #ff48fa);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;

  &:hover {
    box-shadow: none;
    color: white;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.container {
  .sublier-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font: normal normal normal 36px/51px Gilroy-SemiBold;
      color: #000000;
      margin: 0%;
    }
  }

  p {
    font: normal normal normal 18px/26px Gilroy;
    color: #444444;
  }

  h5 {
    font: normal normal normal 22px Gilroy-Medium;
    color: #888888;
    margin: 0;
  }

  form {
    font: normal normal normal 20px/28px Gilroy-Medium;
    color: #222222;

    .form-group {
      margin-bottom: 20px;

      @media screen and (min-width: 600px) {
        margin-bottom: 0;
      }
    }

    .ant-select.ant-select-single.ant-select-show-arrow {
      height: 38px;
      width: 100%;

      .ant-select-selector {
        height: 100%;

        .ant-select-selection-item {
          display: flex;
          align-items: center;
        }
      }
    }

    .ant-input-affix-wrapper {
      padding: 6px 12px;
      & > input.ant-input {
        height: 24px;
      }
    }

    .weiVal {
      font-size: 15px;
      margin-top: 5px;
      padding-left: 10px;
    }

    .error {
      margin-top: 10px;
      color: hsl(0, 100%, 69%);
      font-size: 0.9em;
      font: normal normal normal 13px Gilroy;
    }
  }
}

.create-modal {
  .ant-modal {
    width: 80% !important;

    @media screen and (min-width: 500px) {
      width: 50% !important;
    }

    @media screen and (min-width: 768px) {
      width: 40% !important;
    }

    @media screen and (min-width: 1024px) {
      width: 30% !important;
    }

    @media screen and (min-width: 1280px) {
      width: 20% !important;
    }
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5 {
      font: normal normal normal 20px/22px Gilroy-SemiBold;
    }
  }
}
