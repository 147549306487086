.nav-container {
  height: 60px;
  padding: 0px 10px;
  box-shadow: 0px 3px 3px #0000001a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  width: 100%;

  @media (min-width: 768px) {
    height: 48px;
    padding: 0px 15px 0px 15px;
  }

  @media (min-width: 1024px) {
    height: 48px;
    padding: 0px 20px 0px 20px;
  }

  .logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 160px;

    .image {
      width: 100%;
      height: 100%;
    }
  }

  .tabs-container {
    .wallet {
      border-radius: 100rem;
      padding: 1rem;
      font-family: 'Gilroy-SemiBold';
      font-size: 0.7rem;
      padding: 0rem 1rem;
      box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
      border: solid 3px transparent;
      background-image: linear-gradient(
          rgba(100, 61, 61, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #78e4ff, #ff48fa);
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #fff inset;
      color: #000000;

      @media screen and (min-width: 1024px) {
        font-size: 1rem;
      }

      &:hover {
        box-shadow: none;
        color: white;
      }

      .mobile {
        width: 25px;
        height: 25px;
        padding: 3px 0px;

        &:hover {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(325deg)
            brightness(104%) contrast(104%);
        }

        @media screen and (min-width: 500px) {
          display: none;
        }
      }

      .desktop {
        display: none;

        @media screen and (min-width: 500px) {
          display: inline-block;
        }
      }
    }

    .account {
      font: normal normal normal 12px/24px Gilroy-Medium;
      letter-spacing: 0.44px;
      color: #2b8dff;
      text-transform: uppercase;
      margin-left: 10px;

      @media screen and (min-width: 1024px) {
        font-size: 14px;
      }
    }

    .wrong-network {
      border-radius: 100rem;
      padding: 1rem;
      font-family: 'Gilroy-SemiBold';
      font-size: 0.8rem;
      padding: 0rem 1rem;
      border: solid 3px transparent;
      background: #fa433f;
      color: #ffffff;
    }
  }
}

.network {
  outline: none;
  border: none;
  background: none;
  color: #2b8dff;
}
