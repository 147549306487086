.page-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  @media screen and (min-width: 1024px) {
    height: 95vh;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: space-around;
    align-items: center;
  }

  .main-section {
    text-align: center;
    margin-top: 20px;

    @media screen and (min-width: 1024px) {
      margin: 0;
    }

    div {
      @media screen and (min-width: 1024px) {
        padding-left: 50px;
        text-align: left;
        margin: 0;
      }
    }

    h2 {
      font-family: 'Gilroy-Bold';
      font-size: 30px;
      color: #070505;
      margin-bottom: 10px;

      @media screen and (min-width: 1024px) {
        text-align: left;
        font-size: 52px;
      }
    }

    p {
      text-align: left;
      font: normal normal normal 20px/38px Gilroy;
      color: #5d6471;
    }

    h6 {
      margin-top: 20px;

      b {
        margin-right: 20px;
        font: normal normal 800 19px/29px Gilroy;
        color: #050607;
      }

      img {
        height: 30px;
      }
    }
  }

  .background-img {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent url('../../images/bg.png') 0% 0% no-repeat
      padding-box;
    background-size: cover;
    background-position: 49px -129px;

    @media screen and (min-width: 1024px) {
      background-position: 80px -25px;
    }

    img {
      width: 100%;

      @media screen and (min-width: 1024px) {
        padding-right: 50px;
      }
    }
  }
}

.dashboard {
  border-radius: 100rem;
  padding: 1rem;
  font-family: 'Gilroy-SemiBold';
  font-size: 1rem;
  padding: 0rem 1rem;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #78e4ff, #ff48fa);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  color: #000;

  &:hover {
    box-shadow: none;
    color: white;
  }
}
