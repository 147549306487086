@import '~antd/dist/antd.less';

.App {
  text-align: center;
}

.App-header {
  background-color: #f7f8fa;
}

label {
  position: relative;
  text-align: left;
  left: -25%;
}
