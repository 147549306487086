@font-face {
  font-family: 'Gilroy-Thin';
  src: url('./fonts/gilroy-thin-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./fonts/gilroy-regular-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('./fonts/gilroy-medium-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-UltraLight';
  src: url('./fonts/gilroy-ultralight-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-ultralight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Black';
  src: url('./fonts/gilroy-black-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('./fonts/gilroy-extrabold-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: url('./fonts/gilroy-semibold-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Heavy';
  src: url('./fonts/gilroy-heavy-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-heavy-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('./fonts/gilroy-bold-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('./fonts/gilroy-light-webfont.woff2') format('woff2'),
    url('./fonts/gilroy-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f8fa 0% 0% no-repeat padding-box;
  background-color: #f7f8fa !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
